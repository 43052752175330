<template>
  <autobid-page :key="slug" :slug="slug" @error="handleError">
    <template #default="{ pageData }">
      <CommonStrapiPage :page-data="pageData" />
    </template>
  </autobid-page>
</template>

<script lang="ts" setup>
import type { StrapiPageError } from '@autobid/strapi-integration/typescript/strapi/Page'

definePageMeta({
  layout: 'strapi'
})

const slug = useRoute().path

const handleError = (error: StrapiPageError) => {
  showError({
    statusCode: error.code,
    statusMessage: error.message
  })
}
</script>
